<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%;  max-height=90vh;" class="mx-auto">
        <v-row>
          <v-card-title class="font-weight-bold pb-3">
            {{ $t('title.contractor.baseInfo') }}
          </v-card-title>
        </v-row>
        <v-row class="pl-4">
          <p>{{ $t('description.baseInfo') }}</p>
        </v-row>
        <v-row>
          <v-container class="ma-0 pa-0">
            <table
              class="ma-0"
              style="width: 100%; border-spacing: 5px; font-size: 14px; table-layout: fixed;"
            >
              <tbody>
                <tr v-for="baseRecord in baseRecords" :key="baseRecord.key">
                  <td class="text-left base_td_category_text px-2">
                    {{ $t(`header.baseInfoTable.${baseRecord.key}`) }}
                  </td>
                  <td class="pl-2 py-2" style="border: solid 1px #cccccc;">
                    <p
                      class="ma-0"
                      style="word-wrap: break-word;"
                      v-for="(message, index) in splitMessageLine(
                        baseRecord.value
                      )"
                      :key="index"
                    >
                      {{ message }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-container>
        </v-row>
        <div v-if="comment.length > 0">
          <v-row>
            <v-card-title class="font-weight-bold pb-3">
              {{ $t('label.agencyComment') }}
            </v-card-title>
          </v-row>
          <v-row class="pl-4">
            <v-col class="ma-0 pa-0">
              <p
                v-for="(message, index) in comment"
                :key="index"
                style="word-wrap: break-word"
                class="ma-0 pa-0"
              >
                {{ message }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div v-if="extraFiles.length > 0 || notImageFiles.length > 0">
          <v-row>
            <v-card-title class="font-weight-bold mb-0 pb-0">
              {{ $t('label.referenceMaterial') }}
            </v-card-title>
          </v-row>
          <v-row>
            <v-col v-for="file in extraFiles" :key="file.src" class="mt-3">
              <v-container class="pa-0" style="max-width: 400px;">
                <v-row>
                  <p class="float-left mr-3 my-1 pa-0">●{{ file.name }}</p>
                </v-row>
                <v-row>
                  <v-layout
                    justify-center
                    style="width: 400px; height: 300px; max-width:90vw; border: #ddd 3px solid;"
                  >
                    <v-img
                      max-width="100%"
                      max-height="100%"
                      :contain="true"
                      :src="file.src"
                      class="mx-auto my-auto"
                      style="display:block;"
                    />
                  </v-layout>
                </v-row>
                <v-container
                  v-if="file.comment"
                  id="scroll-target"
                  class="overflow-y-auto mx-auto mt-2"
                  style="height: 100px; width: 400px; max-width:100%;  border: #000 1px solid; "
                  wrap
                >
                  <v-row
                    v-for="(message, index) in file.comment"
                    :key="index"
                    class="l-3 my-0 mx-auto"
                    style="max-width:400px;"
                  >
                    {{ message }}
                  </v-row>
                </v-container>
                <v-row class="mt-2">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    :href="file.src"
                    width="35vw"
                    max-width="170px"
                    color="download"
                    :download="file.name"
                    style="font-size:14px"
                    dark
                    rounded
                  >
                    {{ $t('button.downloadExtraFile') }}
                  </v-btn>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row v-for="file in notImageFiles" :key="file.id">
            <v-container>
              <v-row class="my-0">
                <v-col>
                  <p class="float-left mr-3 my-1 pa-0">●{{ file.name }}</p>
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="35vw"
                    max-width="170px"
                    color="download"
                    style="font-size:14px"
                    dark
                    rounded
                    @click="download(file)"
                    >{{ $t('button.downloadExtraFile') }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-container
                  v-if="file.comment"
                  id="scroll-target"
                  class="overflow-y-auto mx-auto mt-2"
                  style="height: 100px; max-width:100%;  border: #000 1px solid; "
                  wrap
                >
                  <v-row
                    v-for="(message, index) in splitMessageLine(file.comment)"
                    :key="index"
                    class="l-3 my-0 mx-auto"
                    style="max-width:100%;"
                  >
                    {{ message }}
                  </v-row>
                </v-container>
            </v-container>
          </v-row>
        </div>
        <v-row class="my-12">
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              href="/tos"
              width="30vw"
              max-width="170px"
              color="back"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              @click="goNextPage"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:20px"
              dark
              rounded
              >{{ $t('button.next') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getBaseInfo,
  getExtraPictureList,
  getExtraPicture,
} from '@/apis/contractor/contracts';
import { mapActions, mapGetters } from 'vuex';
import {
  convertDate,
  convertFullNameKanji,
  convertInsuranceObject,
  splitMessageLine,
  // convertProductName,
} from '@/lib/commonUtil';
import { ExtensionsAllowedInExtraPicture, ProductName } from '@/lib/const';
import { downloader } from '@/lib/commonUtil';

export default {
  props: {
    stepPoint: Number,
  },
  data: () => ({
    baseRecords: [
      { key: 'fullNameKanji', value: '' },
      { key: 'fullNameKana', value: '' },
      { key: 'insuranceType', value: '' },
      { key: 'policyNumber', value: '' },
      { key: 'previousPolicyNumber', value: '' },
      { key: 'startDate', value: '' },
      { key: 'address', value: '' },
      { key: 'location', value: '' },
      { key: 'insuranceObject', value: '' },
    ],
    comment: [],
    extraFiles: [],
    notImageFiles: [],
  }),
  methods: {
    goNextPage() {
      const nextStep = this.stepPoint + 1;
      if (this.pendingStep() < nextStep) this.setPendingStep(nextStep);

      this.$router.push({
        path: '/explan_important_matters',
      });
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    async download(file){
      const blob = await getExtraPicture(file.id);
      downloader(blob, file.name)
    },
    ...mapGetters('contract', ['pendingStep']),
    ...mapActions('contract', ['setPendingStep']),
  },
  async mounted() {
    const response = await getBaseInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      const tmpBaseRecord = [
        {
          key: 'fullNameKanji',
          value: convertFullNameKanji(
            response.fullNameKanji1,
            response.fullNameKanji2,
            response.fullNameKanji3
          ),
        },
        {
          key: 'fullNameKana',
          value: this.$t('label.honorificTitle', {
            name: `${response.fullNameKana1}${
              response.fullNameKana2.length > 0
                ? `\n${response.fullNameKana2}`
                : ''
            }${
              response.fullNameKana3.length > 0
                ? `\n${response.fullNameKana3}`
                : ''
            }`,
          }),
        },
        {
          key: 'insuranceType',
          value:
            response.productName == ProductName.Bl
              ? this.$t('label.productName.Bl')
              : this.$t('label.productName.Bk'),
          // value: convertProductName(
          //   response.productName,
          //   response.insuranceObject1,
          //   response.insuranceObject2
          // ),
        },
        { key: 'policyNumber', value: response.policyNumber },
        {
          key: 'startDate',
          value: convertDate(response.startDate),
        },
        {
          key: 'address',
          value: `${response.contractorAddress1}${response.contractorAddress2}${response.contractorAddress3}`,
        },
        {
          key: 'location',
          value: response.insuranceLocation1 || response.insuranceLocation2 || response.insuranceLocation3
            ? `${response.insuranceLocation1}${
                response.insuranceLocation2 ? response.insuranceLocation2 : ''
              }${
                response.insuranceLocation3 ? response.insuranceLocation3 : ''
              }`
            : this.$t('header.baseInfoTable.sameAsContractorAddress'),
        },
        {
          key: 'insuranceObject',
          value: convertInsuranceObject(
            response.insuranceObject1,
            response.insuranceObject2
          ),
        },
      ];

      // 前契約証券番号がnullでない場合、証券番号の後ろに追加し画面上で表示する
      if (response.previousPolicyNumber) {
        const inserIndex = tmpBaseRecord.findIndex(
          ({ key }) => key === 'policyNumber'
        );
        tmpBaseRecord.splice(inserIndex + 1, 0, {
          key: 'previousPolicyNumber',
          value: response.previousPolicyNumber,
        });
      }
      this.$set(this, 'baseRecords', tmpBaseRecord);

      const comment = response.comment;
      if (comment && comment.length > 0) {
        this.comment = splitMessageLine(comment);
      }
    }
    const files = [];
    this.notImageFiles = [];
    const url = window.URL || window.webkitURL;
    const extraPictureList = await getExtraPictureList();
    for (const data of extraPictureList) {
      let extension = data.name.slice(
        ((data.name.lastIndexOf('.') - 1) >>> 0) + 2
      );
      if (ExtensionsAllowedInExtraPicture.includes(extension)) {
        const blob = await getExtraPicture(data.id);
        if (blob) {
          const src = url.createObjectURL(blob);
          const img = new Image();
          img.onload = () => {
            files.push({
              src: src,
              comment: splitMessageLine(data.comment),
              width: img.width,
              height: img.height,
              name: data.name,
            });
          };
          img.src = src;
        }
      } else {
        this.notImageFiles.push(data);
      }
    }
    this.extraFiles = files;
  },
};
</script>
<style>
.v_base_table_with_border {
  border: solid 1px;
}
.base_td_category_text {
  width: 33%;
  background: #cccccc;
}
.v-data-table {
  overflow: hidden;
}
</style>
